
import { defineComponent, Ref, ref } from "vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import { inspectFaucet } from "@/solana/token";
export default defineComponent({
  components: {
    PublicKeyFormField
  },
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:accountAddress"],
  setup(_, { emit }) {
    const faucetAddress = ref("");
    const inspectingFaucet = ref(false);
    const faucetInfo: Ref<null | {
      faucetKey: string;
      mintPubkey: string;
      adminKey: string | undefined;
      permittedAmount: string;
    }> = ref(null);
    const onInspectFaucet = async () => {
      emit("update:accountAddress", "");
      inspectingFaucet.value = true;
      faucetInfo.value = null;
      try {
        faucetInfo.value = await inspectFaucet(faucetAddress.value);
      } catch (err) {
        inspectingFaucet.value = false;
        throw err;
      }
      inspectingFaucet.value = false;
    };
    return { faucetAddress, inspectingFaucet, onInspectFaucet, faucetInfo };
  }
});
