
import { defineComponent, ref, toRefs } from "vue";
import { airdropTokens } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import { u64 } from "@solana/spl-token";
import tokenFaucetComponents from "./tokenFaucetComponents";

export default defineComponent({
  name: tokenFaucetComponents.TokenAirdrop,
  emits: ["update:accountAddress"],
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const adminSecret = ref("");
    const adminSignsExternally = ref(false);
    const addressToAirdrop = ref("");
    const tokenAmount = ref("");
    const faucetAddress = ref("");

    const airdroppingTokens = ref(false);

    const onAirdropTokens = async () => {
      airdroppingTokens.value = true;
      emit("update:accountAddress", "");
      try {
        const address = await airdropTokens(
          payerSecret.value,
          payerSignsExternally.value,
          faucetAddress.value,
          addressToAirdrop.value,
          adminSecret.value,
          adminSignsExternally.value,
          new u64(tokenAmount.value, 10)
        );
        emit("update:accountAddress", address);
      } catch (err) {
        airdroppingTokens.value = false;
        throw err;
      }
      airdroppingTokens.value = false;
    };

    return {
      adminSecret,
      airdroppingTokens,
      adminSignsExternally,
      tokenAmount,
      addressToAirdrop,
      onAirdropTokens,
      faucetAddress
    };
  }
});
