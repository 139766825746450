
import { defineComponent, ref, toRefs } from "vue";
import { closeFaucet } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import tokenFaucetComponents from "./tokenFaucetComponents";

export default defineComponent({
  name: tokenFaucetComponents.CloseFaucet,
  emits: ["update:accountAddress"],
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const adminSecret = ref("");
    const adminSignsExternally = ref(true);
    const faucetAddress = ref("");
    const rentDestinationAddress = ref("");

    const closingFaucet = ref(false);

    const onCloseFaucet = async () => {
      closingFaucet.value = true;
      emit("update:accountAddress", "");
      try {
        const address = await closeFaucet(
          payerSecret.value,
          payerSignsExternally.value,
          adminSecret.value,
          adminSignsExternally.value,
          faucetAddress.value,
          rentDestinationAddress.value
        );
        emit("update:accountAddress", address);
      } catch (err) {
        closingFaucet.value = false;
        throw err;
      }
      closingFaucet.value = false;
    };

    return {
      adminSecret,
      closingFaucet,
      adminSignsExternally,
      onCloseFaucet,
      faucetAddress,
      rentDestinationAddress
    };
  }
});
