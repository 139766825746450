
import { defineComponent, ref, toRefs } from "vue";
import { createFaucet } from "@/solana/token";
import SecretFormField from "@/components/util/SecretFormField.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import { u64 } from "@solana/spl-token";
import tokenFaucetComponents from "./tokenFaucetComponents";
import { MAX_U64 } from "@/util/u64";

export default defineComponent({
  name: tokenFaucetComponents.CreateFaucet,
  emits: ["update:accountAddress"],
  components: {
    SecretFormField,
    PublicKeyFormField
  },
  props: {
    payerSecret: {
      type: String,
      required: true
    },
    payerSignsExternally: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { payerSecret, payerSignsExternally } = toRefs(props);
    const currentAuthoritySecret = ref("");
    const mintAuthoritySignsExternally = ref(true);
    const tokenMintAddress = ref("");
    const adminAddress = ref("");
    const tokenAmount = ref("");

    const creatingFaucet = ref(false);

    const onCreateFaucet = async () => {
      creatingFaucet.value = true;
      emit("update:accountAddress", "");
      try {
        const address = await createFaucet(
          payerSecret.value,
          payerSignsExternally.value,
          currentAuthoritySecret.value,
          mintAuthoritySignsExternally.value,
          tokenMintAddress.value,
          adminAddress.value,
          tokenAmount.value ? new u64(tokenAmount.value, 10) : MAX_U64()
        );
        emit("update:accountAddress", address);
      } catch (err) {
        creatingFaucet.value = false;
        throw err;
      }
      creatingFaucet.value = false;
    };

    return {
      currentAuthoritySecret,
      creatingFaucet,
      mintAuthoritySignsExternally,
      tokenAmount,
      tokenMintAddress,
      adminAddress,
      onCreateFaucet
    };
  }
});
