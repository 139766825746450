
import { defineComponent, ref, onErrorCaptured, computed } from "vue";
import tokenFaucetComponents from "./tokenFaucetComponents";
import { splitAtUppercase } from "@/util/stringFormatting";
import { chosenCluster } from "@/solana/connection";
import * as SolanaErrorHandler from "@/solana/SolanaErrorHandler";

import Heading from "@/components/util/Heading.vue";
import SecretFormField from "@/components/util/SecretFormField.vue";
import CopyIcon from "@/components/util/CopyIcon.vue";
import TokenAirdrop from "@/components/airdrops/TokenAirdrop.vue";
import FaucetCreator from "@/components/airdrops/FaucetCreator.vue";
import FaucetCloser from "@/components/airdrops/FaucetCloser.vue";
import FaucetInspector from "@/components/airdrops/FaucetInspector.vue";

export default defineComponent({
  components: {
    Heading,
    CopyIcon,
    SecretFormField,
    TokenAirdrop,
    FaucetCreator,
    FaucetCloser,
    FaucetInspector
  },
  setup() {
    const payerSecret = ref("");
    const payerSignsExternally = ref(true);
    const currentTokenFaucetComponent = ref(
      tokenFaucetComponents.InspectFaucet
    );
    const accountAddress = ref("");
    const accountLink = computed(
      () =>
        `https://explorer.solana.com/address/${accountAddress.value}?cluster=${chosenCluster.value}`
    );
    const onUpdateAccountAddress = (address: string) => {
      accountAddress.value = address;
      errorMessage.value = "";
    };

    const errorMessage = ref("");
    onErrorCaptured(err => {
      errorMessage.value = SolanaErrorHandler.getErrorMessage(err as Error);
      return false;
    });

    return {
      tokenFaucetComponents,
      currentTokenFaucetComponent,
      payerSecret,
      payerSignsExternally,
      splitAtUppercase,
      errorMessage,
      accountAddress,
      onUpdateAccountAddress,
      accountLink
    };
  }
});
